function surnameExtractor(fullSurname) {
    const prefixes = ['de', 'del', 'la', 'las', 'los', 'van', 'der'];
    const surnameParts = fullSurname.trim().split(/\s+/);
    let firstSurnameIndex = surnameParts.length - 2;
    let secondSurnameIndex = surnameParts.length - 1;
  
    // Adjust for surname prefixes
    if (prefixes.includes(surnameParts[firstSurnameIndex - 1])) {
      firstSurnameIndex -= 1;
    }
    if (prefixes.includes(surnameParts[secondSurnameIndex - 1])) {
      secondSurnameIndex -= 1;
    }
  
    const firstSurname = surnameParts.slice(0, firstSurnameIndex + 1).join(' ');
    const secondSurname = surnameParts.slice(firstSurnameIndex + 1).join(' ');
  
    return {
      firstSurname,
      secondSurname
    };
  }

  export default surnameExtractor;