import {
  Table,
  Tbody,
  Tr,
  Td,
  Icon,
  AbsoluteCenter,
  Divider,
  Box,
  SimpleGrid,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import { FaEye, FaTrashAlt, FaUpload } from "react-icons/fa";

import { getToken } from 'helpers/auth';
import { fuelDictionary } from 'helpers/fuel';
import { serviceTypeDict } from 'helpers/serviceType';
import Card from "components/card/Card.js";
import { axiosSkodaClient, axiosSwipooClient, axiosStrapiClient } from "fetchClient";
import { cleanBackground } from 'services';
import Information from "views/profile/components/Information";


export default function Vehicle(props) {
  const { data, forceUpdate } = props;

  const [isLoadingCirculationPermit, setIsLoadingCirculationPermit] = useState(false);
  const [isLoadingTechnicalSheet, setIsLoadingTechnicalSheet] = useState(false);

  const [isRemovingCirculationPermit, setIsRemovingCirculationPermit] = useState(false);
  const [isRemovingTechnicalSheet, setIsTechnicalSheet] = useState(false);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const handleUploadCirculationPermitFile = () => {
    circulationPermitInput.current.click();
  };

  const handleUploadTechnicalSheet = () => {
    technicalSheetInput.current.click();
  };

  const handleTechnicalSheetChange = async (e) => {
    try {
      if (e.target.files[0]) {
        setIsLoadingTechnicalSheet(true);

        let cleanFile = e.target.files[0];

        if (cleanFile.type !== 'application/pdf') {
          const buffer = await cleanBackground(e.target.files[0]);
          cleanFile = new Blob([buffer], {type: "image/png"})
        }

        const strapiForm = new FormData();
        strapiForm.append('files', cleanFile);

        const token = getToken();
        const strapiClient = axiosStrapiClient(token);

        const [ image ] = await strapiClient.post(`/upload/`, strapiForm).then(res => res.data)

        await strapiClient.put(`/vehicles/${data.id}`, {
          data: {
            technicalSheet: image.id,
          }
        }).then(res => res.data);
      }
    } finally {
      forceUpdate();
      setIsLoadingTechnicalSheet(false);
    }
  };

  const handleCirculationPermitChange = async (e) => {
    try {
      if (e.target.files[0]) {
        setIsLoadingCirculationPermit(true);

        const token = getToken();
        const strapiClient = axiosStrapiClient(token);
        const swipooClient = axiosSwipooClient('', 'application/json');
        const skodaClient = axiosSkodaClient();

        const strapiForm = new FormData();
        strapiForm.append('files', e.target.files[0]);

        let uri = null;
        const [ image ] = await strapiClient.post(`/upload/`, strapiForm).then(res => res.data);
        if (image.mime === 'application/pdf') {
          [ uri ] = await swipooClient.post('/pdf-to-image', { url: image.url }).then(res =>  res.data);
        } else {
          uri  = image.url;
        };
        const ocrResponse = await skodaClient.post(`/circulationPermit`, {
          uri,
          mime: 'image/jpg'
        }).then(res => res.data);

        await strapiClient.put(`/vehicles/${data.id}`, {
          data: {
            plate: ocrResponse?.plate,
            registrationDate: ocrResponse?.enrollmentDate,
            brand: ocrResponse?.brand,
            model: ocrResponse?.model,
            vin: ocrResponse?.frameNumber,
            cc: ocrResponse?.cc,
            fuel: ocrResponse?.fuel,
            serviceType: ocrResponse?.serviceType,
            circulationPermit: image.id,
          }
        }).then(res => res.data);
      }
    } finally {
      forceUpdate();
      setIsLoadingCirculationPermit(false);
    }
  };

  const handleRemoveFile = async (fileName, fileId) => {
    try {
      if (fileName === 'circulationPermit') {
        setIsRemovingCirculationPermit(true);
      } else if (fileName === 'technicalSheet') {
        setIsTechnicalSheet(true);
      }
      const token = getToken();
      const client = axiosStrapiClient(token);
      await Promise.all([
        client.put(`/vehicles/${data.id}`, {
          data: {
            [fileName]: null,
          }
        }).then(res => res.data),
        client.delete(`/upload/files/${fileId}`).then(res => res.data),
      ]);
    } finally {
      forceUpdate();
      if (fileName === 'circulationPermit') {
        setIsRemovingCirculationPermit(false);
      } else if (fileName === 'technicalSheet') {
        setIsTechnicalSheet(false);
      }
    }
  };

  const circulationPermitInput = useRef(null);
  const technicalSheetInput = useRef(null);

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='20px'>
        Vehículo
      </Text>
      <SimpleGrid columns='3' gap='20px'>
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Marca'
          inputType="text"
          keyName="brand"
          value={data.attributes?.brand}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Modelo'
          inputType="text"
          keyName="model"
          value={data.attributes?.model}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Matricula'
          inputType="text"
          keyName="plate"
          value={data.attributes?.plate}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Bastidor'
          inputType="text"
          keyName="vin"
          value={data.attributes?.vin}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Combustible'
          inputType="text"
          keyName="fuel"
          value={fuelDictionary[data.attributes?.fuel]}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Fecha de matriculación'
          inputType="date"
          keyName="registrationDate"
          value={data.attributes?.registrationDate}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Servicio'
          inputType="text"
          keyName="serviceType"
          value={serviceTypeDict[data.attributes?.serviceType]}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='CC'
          inputType="number"
          keyName="cc"
          value={data.attributes?.cc}
        />
        <Information
          id={data?.id}
          forceUpdate={forceUpdate}
          type="vehicles"
          title='Precio'
          inputType="number"
          keyName="price"
          value={data.attributes?.price ? `${data.attributes?.price} €` : null}
        />
      </SimpleGrid>
      <Box position='relative' padding='10'>
          <Divider />
          <AbsoluteCenter bg='white' px='4'>
            <Text color="gray.500" fontWeight='500' fontSize='md'>
              Documentos
            </Text>
          </AbsoluteCenter>
      </Box>
      <Table variant="striped">
        <Tbody>
          <Tr>
            <Td>Permiso de circulación</Td>
            <Td textAlign="end">
              {data.attributes.circulationPermit.data ?
                  (isRemovingCirculationPermit ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled />:<>
                    <a href={data.attributes.circulationPermit.data.attributes.url} target="_blank" rel="noreferrer">
                      <Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" />
                    </a>
                    <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('circulationPermit', data.attributes.circulationPermit.data.id)} />
                  </>)
                : (isLoadingCirculationPermit ?
                    <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled />
                    :<Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadCirculationPermitFile} />
                  )
              }
              <input type="file" name="circulationPermit" ref={circulationPermitInput} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleCirculationPermitChange} />
            </Td>
          </Tr>
          <Tr>
            <Td>Ficha técnica</Td>
               <Td textAlign="end">
              {data.attributes.technicalSheet.data ? (
                isRemovingTechnicalSheet ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled /> :
                <>
                  <a href={data.attributes.technicalSheet.data.attributes.url} target="_blank" rel="noreferrer"><Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" /></a>
                  <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('technicalSheet', data.attributes.technicalSheet.data.id)} />
                </>)
                : (isLoadingTechnicalSheet ? <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled /> : <Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadTechnicalSheet} />)
              }
              <input type="file" name="technicalSheet" ref={technicalSheetInput} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleTechnicalSheetChange} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Card>
  );
}
