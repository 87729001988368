import React, { useEffect, useState } from "react";

import { AuthContext } from "contexts/AuthContext";
import { API, BEARER } from "constants";
import { getToken , logout } from "helpers/auth";

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const authToken = getToken();

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const data = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      }).then(r => r.status === 401 ? logout() : r.json())
      setUserData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
