import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdFolder,
  MdHome,
  MdDirectionsCar,
} from "react-icons/md";

import MainDashboard from "views/default";
import Profile from "views/profile";
import DataTables from "views/transferencias";
import Results from "views/results";

const routes = [
  {
    name: "Dashboard",
    path: "/",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    sidebar: true,
    component: MainDashboard,
  },
  {
    name: "Transferencias",
    icon: <Icon as={MdFolder} width='20px' height='20px' color='inherit' />,
    path: "/transferencias",
    sidebar: true,
    component: DataTables,
  },
  {
    name: "Matriculaciones",
    icon: <Icon as={MdFolder} width='20px' height='20px' color='inherit' />,
    path: "/matriculaciones",
    sidebar: true,
    component: null,
  },
  {
    name: "Clientes",
    path: "/clientes",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    sidebar: true,
    component: null,
  },
  {
    name: "Vehículos",
    path: "/vehicles",
    icon: <Icon as={MdDirectionsCar} width='20px' height='20px' color='inherit' />,
    sidebar: true,
    component: null,
  },
  {
    name: "",
    path: "/transferencias/:id",
    icon: null,
    sidebar: false,
    component: Profile,
  },
  {
    name: "",
    path: "/search/:queryText",
    icon: null,
    sidebar: false,
    component: Results,
  },
];

export default routes;
