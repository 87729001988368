export const serviceTypeDict = {
    'A00': 'PUBL SIN EPECIFICAR',
    'A01': 'PUBL ALQUILER SIN CONDUCTOR',
    'A02': 'PUBL ALQUILER CON CONDUCTOR',
    'A03': 'PUBL APRENDIZAJE CONDUCCION',
    'A04': 'PUBL TAXI',
    'A05': 'PUBL AUXILIO CARRETERA',
    'A07': 'PUBL AMBULANCIA',
    'A08': 'PUBL FUNERARIO',
    'A10': 'PUBL MERCANCIAS PELIGROSAS',
    'A11': 'PUBL BASURERO',
    'A12': 'PUBL TRANSPORTE ESCOLAR',
    'A13': 'PUBL POLICIA',
    'A14': 'PUBL BOMBEROS',
    'A15': 'PUBL PROTECCION CIVIL Y SALVAMENTO',
    'A16': 'PUBL MINISTERIO DE DEFENSA',
    'A18': 'PUBL ACTIVIDAD ECONOMICA',
    'A20': 'PUBL MERCANCIAS PERECEDERAS',
    'B00': 'PART SIN ESPECIFICAR',
    'B06': 'PART AGRICOLA',
    'B09': 'PART OBRAS',
    'B17': 'PART VIVIENDA',
    'B18': 'PART ACTIVIDAD ECONOMICA',
    'B19': 'PART RECREATIVO',
    'B21': 'PART VEHICULO DE FERIAS',
};