import axios from "axios";

import {
	API,
	SKODA_URI,
	SWIPOO_URI,
	BEARER,
	REACT_APP_GRUPO_RUEDA_API_KEY,
	REACT_APP_GESTORIA_RUEDA_API_KEY,
} from './constants';

export const axiosStrapiClient = (token) => axios.create({
  	baseURL: API,
	headers: {
		Authorization: `${BEARER} ${token}`,
	},
})

export const axiosSwipooClient = (version, contentType) => axios.create({
	baseURL: `${SWIPOO_URI}/${version}`,
	headers: {
		Authorization: REACT_APP_GRUPO_RUEDA_API_KEY,
		'x-origin': 'grupo_rueda',
		'Content-Type': contentType ? contentType : 'multipart/form-data',
	},
  })

export const axiosSkodaClient = () => axios.create({
	baseURL: `${SKODA_URI}/ocr`,
	headers: {
		Authorization: `${BEARER} ${REACT_APP_GESTORIA_RUEDA_API_KEY}`,
	},
})

