import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  MdAdd
} from "react-icons/md";
import { useHistory, Link } from "react-router-dom";


import Card from "components/card/Card";
import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";

export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const iconColor = useColorModeValue("brand.500", "white");

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  let history = useHistory();

  const handleCreateNewTransaction = async () => {
    const token = getToken();
    const client = axiosStrapiClient(token);

    const [
      vehicle,
      buyerPerson,
      sellerPerson,
    ] = await Promise.all([
      client.post("/vehicles", {
        data: {}
      }).then(res => res.data),
      client.post("/people", {
        data: {}
      }).then(res => res.data),
      client.post("/people", {
        data: {}
      }).then(res => res.data),
    ]);

    const [
      buyerCompany,
      sellerCompany
    ] = await Promise.all([
      client.post("/companies", {
        data: {
          representative: [buyerPerson.data.id]
        }
      }).then(res => res.data),
      client.post("/companies", {
        data: {
          representative: [sellerPerson.data.id]
        }
      }).then(res => res.data)
    ]);

    const transaction = await client.post("/transactions", {
      data: {
        vehicle: [vehicle.data.id],
        buyerPerson:[buyerPerson.data.id],
        sellerPerson: [sellerPerson.data.id],
        buyerCompany: [buyerCompany.data.id],
        sellerCompany: [sellerCompany.data.id]
      }
    }).then(res => res.data);
    history.push(`/transferencias/${transaction.data.id}`)
  };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      pb='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Transferencias
        </Text>
        <Button
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          borderRadius='10px'
          onClick={handleCreateNewTransaction}
        >
        <Icon as={MdAdd} color={iconColor} w='24px' h='24px' />
      </Button>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) =>
									<Td
										{...cell.getCellProps()}
										key={index}
										fontSize={{ sm: "14px" }}
										minW={{ sm: "150px", md: "200px", lg: "auto" }}
										borderColor='transparent'>
										{cell?.column?.Header === 'Acciones' ?
                      <Link to={`/transferencias/${row.cells[0].value}`}>
                        <Text color={iconColor} fontSize='sm' fontWeight='600' cursor="pointer">
										      Ver
										    </Text>
                      </Link> :
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
											  {cell.value}
										  </Text>}
									</Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
