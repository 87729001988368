import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Button, Flex, Text } from "@chakra-ui/react";

import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";
import { parseDayMonthYear } from 'helpers/date';
import ColumnsTable from "views/transferencias/components/ColumnsTable";

export default function Transferencias() {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchTransactions = async (page) => {
    const token = getToken();
    const client = axiosStrapiClient(token);
    const response = await client.get(`/transactions?populate=*&sort[0]=createdAt:desc&pagination[page]=${page}&pagination[pageSize]=10`).then(res => res.data);
    setTransactions(response.data.map(t => {
      const buyerPerson = t?.attributes?.buyerPerson?.data?.attributes;
      const buyerCompany = t?.attributes?.buyerCompany?.data?.attributes;
      const client = buyerPerson?.name ? `${buyerPerson?.name || ''} ${buyerPerson?.firstSurname || ''} ${buyerPerson?.secondSurname || ''}` : buyerCompany?.legalName;

      return ({
        id: t.id,
        plate: t?.attributes?.vehicle?.data?.attributes?.plate,
        client,
        date: parseDayMonthYear(t?.attributes?.createdAt),
      })
    }));
    setTotalPages(response.meta.pagination.pageCount);
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - halfMaxPagesToShow && i <= currentPage + halfMaxPagesToShow)) {
        pageNumbers.push(
          <Button
            key={i}
            onClick={() => handlePageChange(i)}
            variant={i === currentPage ? "solid" : "outline"}
            mx="5px"
          >
            {i}
          </Button>
        );
      } else if (i === currentPage - halfMaxPagesToShow - 1 || i === currentPage + halfMaxPagesToShow + 1) {
        pageNumbers.push(<Text key={i} mx="5">...</Text>);
      }
    }
    return pageNumbers;
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        spacing={{ base: "20px", xl: "20px" }}>
        <ColumnsTable
          columnsData={[
            {
              Header: "Id",
              accessor: "id",
            },
            {
              Header: "Matrícula",
              accessor: "plate",
            },
            {
              Header: "Cliente",
              accessor: "client",
            },
            {
              Header: "Fecha",
              accessor: "date",
            },
            {
              Header: "Acciones",
              accessor: null,
            },
          ]}
          tableData={transactions}
        />
      </SimpleGrid>
      <Flex justifyContent="center" mt="20px">
        <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} mx="5px">
          Anterior
        </Button>
        {renderPageNumbers()}
        <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} mx="5px">
          Siguiente
        </Button>
      </Flex>
    </Box>
  );
}
