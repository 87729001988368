import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Text, Spinner, Flex } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';

import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";
import { parseDayMonthYear } from 'helpers/date';
import ColumnsTable from "views/transferencias/components/ColumnsTable";


export default function Results() {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);  

  const { queryText } = useParams();

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true);  
      const token = getToken();
      const client = axiosStrapiClient(token);
      
      const normalizedQuery = queryText.toLowerCase().replace(/\s+/g, ' ');
      const queryParts = normalizedQuery.split(' ');
      const [brandFilter, modelFilter] = [queryParts[0], queryParts.slice(1).join(' ')];

      const combinedFilter = brandFilter && modelFilter 
        ? `&filters[$or][23][$and][0][vehicle][brand][$containsi]=${brandFilter}&filters[$and][1][vehicle][model][$containsi]=${modelFilter}`
        : '';

      
      const query = `/transactions?populate=deep&filters[$or][0][buyerPerson][name][$containsi]=${queryText}` +
      `&filters[$or][1][buyerPerson][firstSurname][$containsi]=${queryText}` +
      `&filters[$or][2][buyerPerson][secondSurname][$containsi]=${queryText}` +
      `&filters[$or][3][buyerPerson][dni][$containsi]=${queryText}` +
      `&filters[$or][4][buyerPerson][phone][$containsi]=${queryText}` +
      `&filters[$or][5][buyerPerson][address][$containsi]=${queryText}` +
      `&filters[$or][6][buyerPerson][province][$containsi]=${queryText}` +
      `&filters[$or][7][buyerPerson][zipCode][$containsi]=${queryText}` +
      `&filters[$or][8][buyerPerson][email][$containsi]=${queryText}` +
      `&filters[$or][9][sellerPerson][name][$containsi]=${queryText}` +
      `&filters[$or][10][sellerPerson][firstSurname][$containsi]=${queryText}` +
      `&filters[$or][11][sellerPerson][secondSurname][$containsi]=${queryText}` +
      `&filters[$or][12][sellerPerson][dni][$containsi]=${queryText}` +
      `&filters[$or][13][sellerPerson][phone][$containsi]=${queryText}` +
      `&filters[$or][14][sellerPerson][address][$containsi]=${queryText}` +
      `&filters[$or][15][sellerPerson][province][$contains]=${queryText}` +
      `&filters[$or][16][sellerPerson][zipCode][$containsi]=${queryText}` +
      `&filters[$or][17][buyerCompany][nif][$contains]=${queryText}` +
      `&filters[$or][18][buyerCompany][legalName][$containsi]=${queryText}` +
      `&filters[$or][19][sellerCompany][nif][$containsi]=${queryText}` +
      `&filters[$or][20][sellerCompany][legalName][$contains]=${queryText}` +
      `&filters[$or][21][vehicle][plate][$containsi]=${queryText}` +
      `&filters[$or][22][vehicle][vin][$containsi]=${queryText}` +
      combinedFilter +
      `&pagination[page]=1&pagination[pageSize]=10`;

      const response = await client.get(query).then(res => res.data);

      setResults(response.data.map(t => {
        const buyerPerson = t?.attributes?.buyerPerson?.data?.attributes;
        const buyerCompany = t?.attributes?.buyerCompany?.data?.attributes;
        const client = buyerPerson?.name ? `${buyerPerson?.name || ''} ${buyerPerson?.firstSurname || ''} ${buyerPerson?.secondSurname || ''}` : buyerCompany?.legalName;

        return ({
          id: t.id,
          plate: t?.attributes?.vehicle?.data?.attributes?.plate,
          client,
          date: parseDayMonthYear(t?.attributes?.createdAt),
            })
        }));
        setIsLoading(false);  
    };
    fetchResults();
  }, [queryText]);

  if (isLoading) return (
    <Flex direction="column" align="center" justify="center" height="100vh">
      <Text fontSize="xl">Cargando Resultados...</Text>
      <Spinner size="xl" mt={4} />
    </Flex>
  );

  if (results.length === 0) return <Box textAlign="center" mt="200px">No se han encontrado resultados.</Box>;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mt='20px'
        spacing={{ base: "20px", xl: "20px" }}>
        <ColumnsTable
          columnsData={[
            {
              Header: "Id",
              accessor: "id",
            },
            {
              Header: "Matrícula",
              accessor: "plate",
            },
            {
              Header: "Cliente",
              accessor: "client",
            },
            {
              Header: "Fecha",
              accessor: "date",
            },
            {
              Header: "Acciones",
              accessor: null,
            },
          ]}
          tableData={results}
        />
      </SimpleGrid>
    </Box>
  );
}
