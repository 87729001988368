import React, { useState } from "react";
import { Flex, Input, Box, Text, Select, Icon, useColorModeValue } from "@chakra-ui/react";
import { GoPencil } from "react-icons/go";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";
import { ccaaDictionary } from 'helpers/ccaa';
import { fuelDictionary } from "helpers/fuel";
import { serviceTypeDict } from 'helpers/serviceType';
import Card from "components/card/Card.js";

export default function Information(props) {
  const { title, id, type, keyName, inputType, value, forceUpdate } = props;

  const [text, setText] = useState(value);
  const [isEditable, setIsEditable] = useState(false);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const handleEditView = () => {
    setIsEditable(true);
  };

  const handleSubmitEdit = async () => {
    try {
      const token = getToken();
      const client = axiosStrapiClient(token);
      const response = await client.put(`/${type}/${id}`, {
        data: {
          [keyName] : text,
        }
      }).then(res => res.data);
      setText(response.data.attributes[keyName]);
      setIsEditable(false);
    } finally {
      forceUpdate();
    }
  };

  const handleChange = (e) => {
    setText(e.target.value)
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitEdit();
    }
  };

  const computeValue = () => {
    if (inputType === 'date') return value?.split('-')?.reverse()?.join('/') || null;
    if (keyName === 'ccaa') return ccaaDictionary[value];
    return value;
  };

  const computeInput = () => {
    switch (keyName) {
      case 'ccaa':
        return (
          <Select onChange={handleChange} value={text}>
            {Object.keys(ccaaDictionary).map(ccaa => <option value={ccaa}>{ccaaDictionary[ccaa]}</option>)}
          </Select>
        );
      case 'fuel':
        return (
          <Select onChange={handleChange} value={text}>
            {Object.keys(fuelDictionary).map(f => <option value={f}>{fuelDictionary[f]}</option>)}
          </Select>
        );
      case 'serviceType':
        return (
          <Select onChange={handleChange} value={text}>
            {Object.keys(serviceTypeDict).map(st => <option value={st}>{serviceTypeDict[st]}</option>)}
          </Select>
        );
      default:
        return <Input variant="flushed" value={text} onChange={handleChange} onKeyDown={handleKeyDown} type={inputType} />;
    }
  };


  return (
    <Card bg={bg} boxShadow={cardShadow}>
      <Box>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
          {title}
        </Text>
        <Flex justify="space-between">
          {isEditable ?
              <>
                {computeInput()}
              </>
            : <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                {computeValue()}
              </Text>}
          {isEditable ?
            <Icon as={IoIosCheckmarkCircleOutline} w='20px' h='20px' color={textColorSecondary} margin="auto" cursor="pointer" onClick={handleSubmitEdit} />
            : <Icon as={GoPencil} w='15px' h='15px' color={textColorSecondary} mt="auto" mb="auto" cursor="pointer" onClick={handleEditView} /> }
        </Flex>
      </Box>
    </Card>
  );
}
