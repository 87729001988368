import axios from 'axios';

import { axiosSwipooClient } from 'fetchClient';

export const cleanBackground = async (file) => {
    try {
      const data = new FormData();
  
      data.append('image_file', file);
  
      const response = await axios({
        method: 'POST',
        url: 'https://sdk.photoroom.com/v1/segment',
        headers: {
          'X-API-Key': process.env.REACT_APP_PHOTOROOM_API_KEY,
        },
        data,
        responseType: 'arraybuffer',
      }).then((r) => r.data);
  
      return response;
    } catch (err) {
      throw err;
    }
  };

export const getFullInfoFromAddress = async (address) => {
  try {
    const swipooClient = axiosSwipooClient('');
    return swipooClient.get(`/full-info-from-address?address=${address}`).then(res => res.data);
  } catch (err) {
    throw err;
  }
};

export const parseAddress = async (address) => {
  try {
    return axios.get(`https://doo0v8ym26.execute-api.eu-west-1.amazonaws.com/parse-address?address=${address}`).then(res => res.data);
  } catch (err) {
    throw err;
  }
};

export const getMunicipalityFromZipCode = async (zipCode) => axios.get(`https://husqvarna.swipoo-bc3.workers.dev/zipCode/${zipCode}`).then(res => res.data[0]);