import React, { useEffect, useState } from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";
import BarChart from "components/charts/BarChart";
import Card from "components/card/Card";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const ALL_ENTRIES = 999999;

  const [isLoading, setIsLoading] = useState(true);

  const [barChartData, setBarChartData] = useState({
    series: [{ name: "Transferencias", data: [] }],
    options: {
      chart: { 
        type: 'bar', 
        height: 350,
        toolbar: { show: false }
      },
      plotOptions: { bar: { horizontal: false, columnWidth: '55%' } },
      dataLabels: { enabled: false },
      xaxis: { categories: [] },
      fill: { opacity: 1 },
      tooltip: { y: { formatter: val => val } },
    }
  });

  useEffect(() => {
    const fetchWeeklyTransfers = async () => {
      const token = getToken();
      const client = axiosStrapiClient(token);
      const endDate = new Date();

      endDate.setHours(23, 59, 59, 999); 
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 6);
      startDate.setHours(0, 0, 0, 0); 

      const dayNames = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];

      try {
        const response = await client.get(`/transactions?filters[createdAt][$gte]=${startDate.toISOString()}&filters[createdAt][$lte]=${endDate.toISOString()}&pagination[page]=1&pagination[pageSize]=${ALL_ENTRIES}`);
        const transactions = response.data.data;

        const labels = Array.from({ length: 7 }).reduce((acc, _, i) => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + i);
          const dayName = dayNames[date.getDay()];
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          acc.push(`${dayName}  ${day} / ${month}`);
          return acc;
        }, []);
        
        const dailyCounts = transactions.reduce((acc, t) => {
          const createdAt = new Date(t.attributes.createdAt);
          createdAt.setHours(0, 0, 0, 0); 
        
          const dayIndex = Math.floor((createdAt - startDate) / (1000 * 60 * 60 * 24));
          if (dayIndex >= 0 && dayIndex < 7) {
            acc[dayIndex]++;
          }
          return acc;
        }, Array(7).fill(0));
        

        setBarChartData({
          series: [{ name: "Transferencias", data: dailyCounts }],
          options: {
            chart: { 
              type: 'bar', 
              height: 350,
              toolbar: { show: false } 
            },
            plotOptions: { bar: { horizontal: false, columnWidth: '55%' } },
            dataLabels: { enabled: false },
            xaxis: { categories: labels },
            fill: { opacity: 1 },
            tooltip: { y: { formatter: val => val } },
          }
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Error al cargar las transferencias:', error.response ? error.response.data : error.message);
      }
    };

    fetchWeeklyTransfers();
  }, []);

  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Trámites Semanales
        </Text>
      </Flex>
      <Box w='100%' h={{ base: "200px", lg: "300px" }} ps='8px'>
        {isLoading ? (
          <Text>Cargando...</Text>
        ) : (
          <BarChart
            chartData={barChartData.series}
            chartOptions={barChartData.options}
          />
        )}
      </Box>
    </Card>
  );
}
