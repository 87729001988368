import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ chartData, chartOptions }) => {
  return <Chart options={chartOptions} series={chartData.datasets} type="line" height="300" />;
};

export default LineChart;


