import React, { useState } from "react";
import { Input, Flex, Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';

import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";
import { parseDayMonthYearHourMinute } from 'helpers/date';
import Card from "components/card/Card.js";
import { useAuthContext } from "contexts/AuthContext";

export default function Comments({ comments }) {
const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
const messagebackground = useColorModeValue("secondaryGray.300", "white");
const subtitleMessage = useColorModeValue("secondaryGray.600", "white");

const [text, setText] = useState('');
const [commentList, setCommentList] = useState(comments || []);

const { user } = useAuthContext();
const { id } = useParams();

const handleChange = (e) => {
	setText(e.target.value);
};

const handleNewComment = async (e) => {
	if (e.key === 'Enter'){
		try {
			const token = getToken();
			const client = axiosStrapiClient(token);

			const newComment = await client.post('/comments/', {
				data: {
					comment: text,
					author: user?.username,
				}
			}).then(res => res.data);
			const newCommentListIds = [...commentList.map(c => c.id), newComment.data.id]
			const transaction = await client.put(`/transactions/${id}?populate[0]=comments`, {
				data: {
					comments : newCommentListIds,
				}
			}).then(res => res.data);
			setCommentList(transaction.data.attributes.comments.data);
		} catch(e) {
			// error
		} finally {
			setText('');
		}
  }
};

return (
	<Box pt={{ base: "100px" }} w="33%" ml="20px">
		<Card
			mb={{ base: "0px", "2xl": "20px" }}
			position="fixed"
			h="77vh"
			w={{
				base: 'calc(100vw - 76%)',
				md: 'calc(100vw - 76%)',
				lg: 'calc(100vw - 70%)',
				xl: 'calc(100vw - 76%)',
				'2xl': 'calc(100vw - 74%)'
			}}>
			<Text
				color={textColorPrimary}
				fontWeight='bold'
				fontSize='xl'
				mt='2px'
				mb='20px'>
				Comentarios
			</Text>
			<Box overflowY="auto" height="100%">
				{!!commentList.length && commentList.map(c => (
					<Box
						key={c.id}
						background={messagebackground}
						borderRadius="0px 20px 20px"
						p="16px 0px"
						paddingInline="24px"
						mb="20px"
					>
						<Text
							color={textColorPrimary}
							fontSize="md"
							mb="8px"
							fontWeight="400"
						>
							{c.attributes.comment}
						</Text>
						<Flex justifyContent="space-between">
							<Text
								color={subtitleMessage}
								fontSize="xs"
								fontWeight="500"
							>
								{parseDayMonthYearHourMinute(c.attributes.createdAt)}
							</Text>
							<Text
								color={subtitleMessage}
								fontSize="xs"
								fontWeight="500"
							>
								{c.attributes.author}
							</Text>
						</Flex>
					</Box>
				))}
			</Box>
			<Input
				value={text}
				onChange={handleChange}
				placeholder="Nuevo comentario"
				borderRadius="16px"
				mt="10px"
				onKeyUp={handleNewComment}
			/>
		</Card>
	</Box>
);
}
