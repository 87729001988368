import {
    Table,
    Tbody,
    Tr,
    Td,
    Icon,
    Text,
    useColorModeValue,
    Button,
    Flex,
    Box,
  } from "@chakra-ui/react";
  import React, { useState, useRef } from "react";
  import { FaEye, FaTrashAlt, FaUpload } from "react-icons/fa";
  import { MdUpload } from "react-icons/md";
  
  import { getToken } from 'helpers/auth';
  import Card from "components/card/Card.js";
  import { axiosStrapiClient } from "fetchClient";
  import Dropzone from "views/profile/components/Dropzone";
  import ExtraFileRow from "views/profile/components/ExtraFileRow";

  export default function TransactionFiles(props) {
    const { invoice, contract, transactionId, extraFiles, forceUpdate, ...rest } = props;

    const [isLoadingContract, setIsLoadingContract] = useState(false);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);

    const [isRemovingContract, setIsRemovingContract] = useState(false);
    const [isRemovingInvoice, setIsRemovingInvoice] = useState(false);

    const [dropzoneIsLoading, setDropzoneIsLoading] = useState(false);

    const brandColor = useColorModeValue("brand.500", "white");
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

    const handleUploadContractFile = () => {
      contractInput.current.click();
    };

    const handleUploadInvoice = () => {
      invoiceInput.current.click();
    };

    const handleInvoiceChange = async (e) => {
      try {
        if (e.target.files[0]) {
          setIsLoadingInvoice(true);

          const strapiForm = new FormData();
          strapiForm.append('files', e.target.files[0]);
  
          const token = getToken();
          const strapiClient = axiosStrapiClient(token);

          const [ image ] = await strapiClient.post(`/upload/`, strapiForm).then(res => res.data)
  
          await strapiClient.put(`/transactions/${transactionId}`, {
            data: {
              invoice: image.id,
            }
          }).then(res => res.data);
        }
      } finally {
        forceUpdate();
        setIsLoadingInvoice(false);
      }
    };
  
    const handleContractChange = async (e) => {
      try {
        if (e.target.files[0]) {
          setIsLoadingContract(true);

          const strapiForm = new FormData();
          strapiForm.append('files', e.target.files[0]);
  
          const token = getToken();
          const strapiClient = axiosStrapiClient(token);
  
          const [ image ] = await strapiClient.post(`/upload/`, strapiForm).then(res => res.data);
  
          await strapiClient.put(`/transactions/${transactionId}`, {
            data: {
              contract: image.id,
            }
          }).then(res => res.data);
        }
      } finally {
        forceUpdate();
        setIsLoadingContract(false);
      }
    };

    const handleDropzone = async (files) => {
      try {
        setDropzoneIsLoading(true);
        const strapiForm = new FormData();

        files.forEach(f => {
          strapiForm.append('files', f);
        });
  
        const token = getToken();
        const strapiClient = axiosStrapiClient(token);
  
        const images = await strapiClient.post(`/upload/`, strapiForm).then(res => res.data);

        const currentExtraFiles = extraFiles.data?.map(f => f.id) || [];

        await strapiClient.put(`/transactions/${transactionId}`, {
          data: {
            extraFiles: [...currentExtraFiles, ...images.map(f => f.id)],
          }
        });

      } finally {
        setDropzoneIsLoading(false);
        forceUpdate();
      }
    };

    const handleRemoveFile = async (fileName, fileId) => {
      try {
        if (fileName === 'contract') {
          setIsRemovingContract(true);
        } else if (fileName === 'invoice') {
          setIsRemovingInvoice(true);
        }
        const token = getToken();
        const client = axiosStrapiClient(token);
        await Promise.all([
          client.put(`/transactions/${transactionId}`, {
            data: {
              extraFiles: extraFiles.data?.map(f => f.id),
            }
          }).then(res => res.data),
          client.delete(`/upload/files/${fileId}`).then(res => res.data),
        ]);
      } finally {
        forceUpdate();
        if (fileName === 'contract') {
          setIsRemovingContract(false);
        } else if (fileName === 'invoice') {
          setIsRemovingInvoice(false);
        }
      }
    };

    const contractInput = useRef(null);
    const invoiceInput = useRef(null);

    return (
      <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='xl'
          mt='10px'
          mb='20px'>
          Otros documentos
        </Text>
        <Table variant="striped">
          <Tbody>
            <Tr>
              <Td>Contrato de compraventa</Td>
              <Td textAlign="end">
                {contract.data ?
                    (isRemovingContract ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled />:<>
                      <a href={contract.data.attributes.url} target="_blank" rel="noreferrer">
                        <Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" />
                      </a>
                      <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('contract', contract.data.id)} />
                    </>)
                  : (isLoadingContract ?
                      <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled />
                      :<Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadContractFile} />
                    )
                }
                <input type="file" name="contract" ref={contractInput} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleContractChange} />
              </Td>
            </Tr>
            <Tr>
              <Td>Factura</Td>
                 <Td textAlign="end">
                {invoice.data ? (
                  isRemovingInvoice ? <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled /> :
                  <>
                    <a href={invoice.data.attributes.url} target="_blank" rel="noreferrer"><Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" /></a>
                    <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile('invoice', invoice.data.id)} />
                  </>)
                  : (isLoadingInvoice ? <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled /> : <Icon as={FaUpload} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" onClick={handleUploadInvoice} />)
                }
                <input type="file" name="invoice" ref={invoiceInput} style={{ display: 'none' }} accept="image/*, application/pdf" onChange={handleInvoiceChange} />
              </Td>
            </Tr>
            {extraFiles.data?.length && extraFiles.data.map((file) => <ExtraFileRow key={file.id} file={file} forceUpdate={forceUpdate} transactionId={transactionId} extraFiles={extraFiles} />)}
          </Tbody>
        </Table>
        <Flex h='200px' direction={{ base: "column" }} mt="20px">
          <Dropzone
              handleDrop={handleDropzone}
              w={{ base: "100%" }}
              me='36px'
              content={dropzoneIsLoading ?
                  <Button isLoading loadingText="Cargando" variant="ghost" height="20px" px="0px" isDisabled />
                : (<Box>
                  <Icon as={MdUpload} w='60px' h='60px' color={brandColor} />
                  <Flex justify='center' mx='auto' mb='12px'>
                    <Text fontSize='xl' fontWeight='700' color={brandColor}>
                      Subir archivos
                    </Text>
                  </Flex>
                  <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                    PNG, JPG and PDF hasta 25MB
                  </Text>
                </Box>)
              }
            />
        </Flex>
      </Card>
    );
  }
