import React, { useEffect, useState } from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { lineChartOptionsTotalSpent } from "variables/charts";
import LineChart from "components/charts/LineChart";
import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const ALL_ENTRIES = 999999;

const MonthlyTransactions = () => {
  const [chartData, setChartData] = useState({
    labels: months,
    datasets: [
      {
        name: "Transferencias",
        data: Array(12).fill(0),
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      const token = getToken();
      const client = axiosStrapiClient(token);

      try {
        const { data: transactions } = await client.get(`/transactions?pagination[page]=1&pagination[pageSize]=${ALL_ENTRIES}`).then(res => res.data);

        const monthlyCounts = transactions.reduce((acc, t) => {
          const date = new Date(t.attributes.createdAt);
          const month = date.getMonth();
          acc[month]++;
          return acc;
        }, Array(12).fill(0));
        

        setChartData({
          labels: months,
          datasets: [
            {
              name: "Transferencias",
              data: monthlyCounts,
            },
          ],
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Error al cargar las transferencias:', error.response ? error.response.data : error.message);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <Card>
      <Flex direction='column' w='100%'>
        <Box px='6px' mb='20px'>
          <Text color={useColorModeValue("secondaryGray.900", "white")} fontSize='xl' fontWeight='700' lineHeight='100%'>
            Trámites Mensuales
          </Text>
        </Box>
        <Box w='100%' h={{ base: "200px", lg: "300px" }} ps='8px'>
          {isLoading ? (
            <Text>Cargando...</Text>
          ) : (
            <LineChart chartData={chartData} chartOptions={lineChartOptionsTotalSpent} />
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default MonthlyTransactions;









