import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";

import "assets/css/App.css";
import theme from "theme/theme";
import { getToken } from 'helpers/auth';
import AppRoutes from "layouts/AppRoutes";
import Login from 'layouts/Login';
import AuthProvider from "components/AuthProvider";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <AuthProvider>
          <BrowserRouter>
            <Switch>
              {getToken() ? <AppRoutes /> : <Login />}
            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);