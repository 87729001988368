import {
    Tr,
    Td,
    Icon,
    Button,
  } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaEye, FaTrashAlt } from "react-icons/fa";

import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";

function ExtraFileRow ({ file, transactionId, forceUpdate, extraFiles }) {
    const [isRemovingFile, setIsRemovingFile] = useState(false);

    const handleRemoveFile = async (fileId) => {
        try {
          setIsRemovingFile(true);
          const token = getToken();
          const client = axiosStrapiClient(token);
          await Promise.all([
            client.put(`/transactions/${transactionId}`, {
              data: {
                extraFiles: extraFiles.data?.filter(f => f.id !== file.id) || [],
              }
            }).then(res => res.data),
            client.delete(`/upload/files/${fileId}`).then(res => res.data),
          ]);
        } finally {
          forceUpdate();
          setIsRemovingFile(false);
        }
      };

    return (
        <Tr>
            <Td>{file.attributes?.name}</Td>
            <Td textAlign="end">
            {(isRemovingFile ?
                <Button isLoading loadingText="Eliminando" variant="ghost" height="20px" px="0px" isDisabled /> :
                <>
                    <a href={file.attributes.url} target="_blank" rel="noreferrer"><Icon as={FaEye} w='20px' h='20px' color="gray.400" margin="auto" cursor="pointer" /></a>
                    <Icon as={FaTrashAlt} ml="15px" w='20px' h='20px' color="gray.400" cursor="pointer" onClick={() => handleRemoveFile(file.id)} />
                </>
            )}
            </Td>
      </Tr>
    )
}

export default ExtraFileRow;