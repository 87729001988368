import React, { useEffect, useState, useReducer } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';

import TransactionFiles from "views/profile/components/TransactionFiles";
import Comments from "views/profile/components/Comments.js";
import Vehicle from 'views/profile/components/Vehicle'
import Person from "views/profile/components/Person";
import Company from "views/profile/components/Company";
import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";
import { downloadXml } from "helpers/download";
import { createTemplateP2P } from "helpers/xmlTemplates/personToPerson";
import { createTemplateC2C } from "helpers/xmlTemplates/companyToCompany";
import { createTemplateC2P } from "helpers/xmlTemplates/companyToPerson";
import { createTemplateP2C } from "helpers/xmlTemplates/personToCompany";

export default function Overview() {
  let mainText = useColorModeValue('navy.700', 'white');
  const [transaction, setTransaction] = useState(null);
  const [isXMLButtonLoading, setIsXMLButtonLoading] = useState(false);
  const [XMLTemplateError, setXMLTemplateError] = useState(false);
  const [updater, forceUpdate] = useReducer(x => x + 1, 0)

  const { id } = useParams();

  useEffect(() => {
    const fetchTransaction = async () => {
      const token = getToken();
      const client = axiosStrapiClient(token);
      const transaction = await client.get(`/transactions/${id}?populate=deep`).then(res => res.data);
      setTransaction(transaction);
    };
    fetchTransaction();
  }, [id, updater])

  if (!transaction) return null;

  const buyerPerson = transaction.data.attributes.buyerPerson.data;
  const sellerPerson = transaction.data.attributes.sellerPerson.data;
  const buyerCompany = transaction.data.attributes.buyerCompany.data;
  const sellerCompany = transaction.data.attributes.sellerCompany.data;
  const vehicle = transaction.data.attributes.vehicle.data;
  const comments = transaction.data.attributes.comments.data;
  const buyerType = transaction.data.attributes.defaultBuyerType;
  const sellerType = transaction.data.attributes.defaultSellerType;

  const handleClick = async () => {
    try {
      setXMLTemplateError(false);
      setIsXMLButtonLoading(true);
      const fileName = `transferencia_${vehicle?.attributes?.plate}`;
      if (buyerType === 'person' && sellerType === 'person') {
        downloadXml(await createTemplateP2P(buyerPerson, sellerPerson, vehicle, transaction), fileName);
      } else if (buyerType === 'company' && sellerType === 'company') {
        downloadXml(await createTemplateC2C(buyerCompany, sellerCompany, vehicle, transaction), fileName);
      } else if (buyerType === 'company' && sellerType === 'person') {
        downloadXml(await createTemplateP2C(buyerCompany, sellerPerson, vehicle, transaction), fileName);
      } else if (buyerType === 'person' && sellerType === 'company') {
        downloadXml(await createTemplateC2P(buyerPerson, sellerCompany, vehicle, transaction), fileName);
      }
    } catch (e) {
      setXMLTemplateError(true);
    } finally {
      setIsXMLButtonLoading(false);
    }
  };

  const handleSelectorBuyer = async (e) => {
    const token = getToken();
    const client = axiosStrapiClient(token);
    const response = await client.put(`/transactions/${id}?populate=deep`, {
      data: {
        defaultBuyerType : e.target.value,
      }
    }).then(res => res.data);
    setTransaction(response);
  };

  const handleSelectorSeller = async (e) => {
    const token = getToken();
    const client = axiosStrapiClient(token);
    const response = await client.put(`/transactions/${id}?populate=deep`, {
      data: {
        defaultSellerType : e.target.value,
      }
    }).then(res => res.data);
    setTransaction(response);
  };

  return (
    <Box display="flex">
      <Box pt={{ base: "10px" }} w="66%">
        <>
          <Flex justifyContent="space-between">
            <Text
              color={mainText}
              bg='inherit'
              mb='40px'
              borderRadius='inherit'
              fontWeight='bold'
              fontSize='34px'
              lineHeight='normal'
              _hover={{ color: { mainText } }}
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}>
                {vehicle?.attributes?.brand} {vehicle?.attributes?.model} - {vehicle?.attributes?.plate}
              </Text>
              <Button cursor="pointer" zIndex="9" variant="brand" isLoading={isXMLButtonLoading} loadingText="Generando..." onClick={handleClick}>Descargar XML</Button>
          </Flex>
          {XMLTemplateError && (
          <Alert status="error" variant="subtle" mb="20px" mt="-20px">
            <AlertIcon />
            <AlertTitle>Error generando XML.</AlertTitle>
            <AlertDescription>Revisa que todos los campos sean correctos.</AlertDescription>
          </Alert>)}
        </>
        {transaction.data.attributes.defaultBuyerType === 'person' ?
          <Person
            pe='20px'
            title="Comprador"
            data={buyerPerson}
            forceUpdate={forceUpdate}
            selectHandler={handleSelectorBuyer}
            defaultSelectValue={transaction.data.attributes.defaultBuyerType}
          />
          : <Company
              pe='20px'
              title="Comprador"
              data={buyerCompany}
              forceUpdate={forceUpdate}
              selectHandler={handleSelectorBuyer}
              defaultSelectValue={transaction.data.attributes.defaultBuyerType}
            />}
        {transaction.data.attributes.defaultSellerType === 'person' ?
          <Person
            mt="20px"
            pe='20px'
            title="Vendedor"
            data={sellerPerson}
            forceUpdate={forceUpdate}
            selectHandler={handleSelectorSeller}
            defaultSelectValue={transaction.data.attributes.defaultSellerType}
          />
          : <Company
              pe='20px'
              title="Vendedor"
              data={sellerCompany}
              forceUpdate={forceUpdate}
              selectHandler={handleSelectorSeller}
              defaultSelectValue={transaction.data.attributes.defaultSellerType}
              />}
        <Vehicle mt="20px" pe='20px' data={vehicle} forceUpdate={forceUpdate} />
        <TransactionFiles
          mt="20px"
          pe='20px'
          transactionId={id}
          invoice={transaction.data.attributes.invoice}
          contract={transaction.data.attributes.contract}
          extraFiles={transaction.data.attributes.extraFiles}
          forceUpdate={forceUpdate}
        />
      </Box>
      <Comments comments={comments}/>
    </Box>
  );
}