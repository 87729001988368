export const streetTypeDict = {
    "ALDEA": 39,
    "APARTAMENTO": 40,
    "AVENIDA": 2,
    "BLOQUE": 42,
    "BARRIO": 4,
    "CALLE": 41,
    "CARRER": 41,
    "CHALET": 43,
    "CAMINO": 44,
    "COLONIA": 45,
    "CARRETERA": 7,
    "CASERÍO": 46,
    "CUESTA": 15,
    "EDIFICIO": 47,
    "GLORIETA": 48,
    "GRUPO": 49,
    "GRAN VÍA": 9,
    "LUGAR": 50,
    "MERCADO": 10,
    "MUNICIPIO": 21,
    "MANZANA": 22,
    "OTROS": 23,
    "PLAZA": 34, 
    "POBLADO": 25,
    "PARTIDA": 33,
    "POLÍGONO": 27,
    "PASAJE": 28,
    "PARQUE": 30,
    "PROLONGACIÓN": 31,
    "PASEO": 32,
    "RAMBLA": 35,
    "RONDA": 36,
    "TRAVESÍA": 37,
    "URBANIZACIÓN": 38,
    "CALLEJÓN": 51,
    "BAJADA": 52,
    "CANAL": 53,
    "LITORAL": 54,
    "SENDERO": 55,
    "AUTOVÍA": 56,
    "BARRANCO": 57,
    "PUENTE": 58,
    "TRÁNSITO": 59,
    "SUBIDA": 60,
};