import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdFolder,
} from "react-icons/md";

import MonthlyTransactions from "views/default/components/MonthlyTransactions";
import WeeklyRevenue from "views/default/components/WeeklyRevenue";

import { getToken } from 'helpers/auth';
import { axiosStrapiClient } from "fetchClient";

export default function UserReports() {
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [selectedTransferType, setSelectedTransferType] = useState('currentMonth');
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const ALL_ENTRIES = 999999;


  useEffect(() => {
    const fetchTransactions = async () => {
      const token = getToken();
      const client = axiosStrapiClient(token);
      const currentDate = new Date();
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
      const currentYear = currentDate.getFullYear();
      let nextMonth = currentDate.getMonth() + 2;
      let nextYear = currentYear;

      if (nextMonth > 12) {
        nextMonth = 1;
        nextYear = currentYear + 1;
      }
      const formattedNextMonth = String(nextMonth).padStart(2, '0');

      let filters = '';
      let apiUrl = '/transactions?';

      if (selectedTransferType === 'currentMonth') {
        filters = `filters[createdAt][$gte]=${currentYear}-${currentMonth}-01&filters[createdAt][$lt]=${nextYear}-${formattedNextMonth}-01&pagination[page]=1&pagination[pageSize]=${ALL_ENTRIES}`;
        apiUrl += `&${filters}`;
      } else if (selectedTransferType === 'anual') {
        filters = `filters[createdAt][$gte]=${currentYear}-01-01&filters[createdAt][$lt]=${currentYear + 1}-01-01&pagination[page]=1&pagination[pageSize]=${ALL_ENTRIES}`;
        apiUrl += `&${filters}`;
      } else if (selectedTransferType === 'total') {
        apiUrl += '&sort[0]=id:desc&pagination[limit]=1'; 
      }

      try {
        const response = await client.get(apiUrl);
        if (selectedTransferType === 'total') {
          setTransactionsCount(Number(response.data.data[0]?.id || 0) - 67); // 67 test transactions  
        } else {
          setTransactionsCount(response.data.data.length);
        }
      } catch (error) {
        console.error('Failed to fetch transactions:', error.response ? error.response.data : error.message);
      }
    };
    fetchTransactions();
  }, [selectedTransferType]);

  const handleTransferTypeChange = (event) => {
    setSelectedTransferType(event.target.value);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box mb="20px">
        <Select
          value={selectedTransferType}
          onChange={handleTransferTypeChange}
          w="150px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ boxShadow: "outline" }}
        >
          <option value="currentMonth">Mes Actual</option>
          <option value="anual">Anual</option>
          <option value="total">Total</option>
        </Select>
      </Box>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        my='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFolder} color={brandColor} />
              }
            />
          }
          name='Transferencias'
          value={transactionsCount}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFolder} color={brandColor} />
              }
            />
          }
          name='Matriculaciones'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFolder} color={brandColor} />
              }
            />
          }
          name='Duplicados'
          value='0'
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' my='20px'>
        <MonthlyTransactions />
        <WeeklyRevenue />
      </SimpleGrid>
    </Box>
  );
}




