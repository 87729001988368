const padNum = (num) => num.toString().padStart(2, "0");

const processDate = (date) => {
  const d = new Date(date);
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getUTCFullYear();

  return { hours, minutes, seconds, day, month, year };
};

export const parseDayMonthYear = (date) => {
  const { day, month, year } = processDate(date);
  return `${padNum(day)}/${padNum(month)}/${year}`;
};

export const parseYearMonthDayDash = (date) => {
  const { day, month, year } = processDate(date);
  return `${year}-${padNum(month)}-${padNum(day)}`;
};

export const parseDayMonthYearHourMinute = (date) => {
  const { day, month, year, hours, minutes } = processDate(date);
  return `${padNum(day)}/${padNum(month)}/${year} ${padNum(hours)}:${padNum(
    minutes,
  )}`;
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  return parseDayMonthYear(currentDate);
};